import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";

import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Poster Projects</Text>
                  <Title className="my-4">
                    Horror Film Poster - Demonio Nostalgia
                  </Title>
                </Box>
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img
                src="/projects/posters/horror-film-poster.jpg?nf_resize=fit&w=1140"
                alt=""
                className="img-fluid w-100"
              />
            </Box>
          </Container>
        </Section>

        <div className="mt-lg-3">
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Title variant="cardBig" className="mt-3 pb-3">
                    Elevator Pitch
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    Demonio Nostalgia is a classic 2000’s teen scream 20 years
                    later. Millennial Jamie Chavez gets more than they bargained
                    for when trying to remember what true blissful childhood
                    happiness felt like. Dissociating from the present leads
                    Jamie to come into contact with a childhood entity that
                    started their downward spiral to depression and anxiety
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
          <div className="mt-lg-3 pt-5">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="8" className="mb-4 mb-lg-0">
                  <Text variant="tag">Tools Used</Text>
                  <Title variant="cardBig" className="mt-3">
                    Photoshop
                  </Title>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col xs="12" className="mb-5">
                <img
                  src="/projects/posters/horror-movie-poster-mockup.jpg?nf_resize=fit&w=1140"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg="dark" className="pt-0">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/little-women-cover">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Reinventing A Classic Book - Little Women
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
